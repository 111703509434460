import React from 'react'

// Components
import Wrapper from './wrapper'
import List from './list'

// Styled Components

export default () => {
    const list = [
        {
            label: `Fax Number`,
            value: `(319) 358-6778`
        }, {
            label: `Office Number`,
            value: `(319) 423-8191`,
            link: `tel:3194238191`
        }, {
            label: `Email Address`,
            value: `info@jphomebuilderscorp.com`,
            link: `mailto:info@jphomebuilderscorp.com`,
            strong: true
        }, {
            label: `Address`,
            value: `945 Alpine Road, Marion, Iowa 52240`,
            link: `https://www.google.com/maps?q=${encodeURIComponent(`945 Alpine Road, Marion, Iowa 52240`)}`
        }
    ]
    return (
        <Wrapper
            title={`Get in Touch`}
        >
            <List items={list}/>
        </Wrapper>
    )
}