import React from 'react'
import styled from 'styled-components'

export default ({
    children,
    name,
    email,
    phone,
    message,
    warning,
    handleSubmit,
    handleChange,
    loading
}) => {
    return (
        <React.Fragment>
            <Title>Give Us Some Details</Title>
            <Description>Have questions or concerns regarding our services? Fill out the form below (or give us a ring), and a customer service representative will reach out to you shortly.</Description>
            <Form onSubmit={handleSubmit}>
                <Input type="text" name="name" value={name} onChange={handleChange} placeholder="Name" required/>
                <Input type="email" name="email" value={email} onChange={handleChange} placeholder="Email" required/>
                <Input type="tel" name="phone" value={phone} onChange={handleChange} placeholder="Phone" required/>
                <Textarea name="message" value={message} onChange={handleChange} placeholder="Message" required/>
                {children}
                { warning.message && <Message success={warning.success}>{ warning.message }</Message> }
                <Button>{loading ? `Sending...` : `Send Message`}</Button>
            </Form>
        </React.Fragment>
    )
}

// Styled Components
const Title = styled.h2`
    font-size: 1.25rem;
    color: ${props => props.theme.color.blue.dark};
    @media (min-width:992px) {
        font-size: 2rem;
    }
`
const Description = styled.p`
    font-size:0.85rem;
    margin: 0.25rem 0 0.75rem 0;
    @media (min-width:992px) {
        font-size: 1rem;
    }
`
const Form = styled.form`
    display:grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap:0.75rem;
`
const Input = styled.input`
    -webkit-appearance:none;
    background-color:rgb(237, 237, 241);
    padding:0.5rem;
    border:none;
    outline:0;
    width:100%;
    border-radius:0.25rem;
`
const Textarea = styled.textarea`
    display:block;
    width:100%;
    background-color:rgb(237, 237, 241);
    padding:0.5rem;
    border:none;
    outline:0;
    border-radius:0.25rem;
    min-width:100%;
    width:100%;
    max-width:100%;
    min-height:9rem;
`
const Button = styled.button`
    letter-spacing:1px;
    font-weight:bold;
    padding: 0.5rem 0.5rem;
    font-size:0.85rem;
    outline:0;
    border:none;
    cursor:pointer;
    width:fit-content;
    transition: all .2s;
    ${props => `
            background-color: ${props.theme.color.blue.primary};
            color:#fff;
            box-shadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    `}
    &:hover {
        box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0,0,0,.12)
    }
    @media (min-width:992px) {
        font-size:1rem;
        padding: 0.75rem 1.5rem;
    }
`
const Message = styled.p`
    padding:0.75rem;
    border-radius:0.25rem;
    ${props => props.success ? `
        background-color: ${props.theme.color.blue.primary};
        color:#fff;
    ` : `
        background-color: #b20020;
        color:#fff;
    `}
`