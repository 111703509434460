import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'

// Components
import Content from './content'

export default () => {

    const defaultFeilds = {
        name: ``,
        email: ``,
        phone: ``,
        message: ``,
    }

    const [ fields, setFields ] = useState({
        ...defaultFeilds
    })

    const [ warning, setWarning ] = useState({
        success: false,
        message: ``,
    })

    const [ loading, setLoading ] = useState(false)

    const recaptcha = useRef(null)

    const handleChange = (e) => {
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const recaptchaValue = recaptcha.current.getValue()

        // Captcha Validation
        if (!recaptchaValue) {
            return setWarning({
                success: false,
                message: `You must complete the captcha.`
            })
        }

        // Set Loading while we process the request
        setLoading(true)

        try {
            // Email Server Url
            const URL = `${process.env.GATSBY_CONTACT_URL}`

            const body = {
                to: `${process.env.GATSBY_EMAIL_ADDRESS}` ,
                from: fields.email,
                subject: `Contact Form Submission`,
                fields: {
                    ...fields
                }
            }

            const response = await fetch(URL, {
                method: 'Post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            })
            
            // If Invalid Response, Return Error
            if (!response || response.status !== 200) {
                throw new Error(e)
            }

            // If message was sent
            setLoading(false)
            setFields({
                ...defaultFeilds
            })
            setWarning({
                success: true,
                message: `Your message has been sent! A customer service representative will get back to you within 24 hours regarding your inquiry.`
            })

        } catch (e) {
            // Reset Fields
            setFields({
                ...defaultFeilds
            })
            // Send Warning
            setWarning({
                success: false,
                message: `Your message could not be sent at this time.`
            })
        }
    }

    const { name, email, phone, message } = fields
    return (
        <Wrapper>
            <Content
                name={name}
                email={email}
                phone={phone}
                message={message}
                warning={warning}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
            >
                <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={`${process.env.GATSBY_RECAPTCHA_KEY}`}
                />
            </Content>
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
`