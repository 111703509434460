import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../components/container'

export default ({
    title,
    description
}) => {
    return (
        <Header>
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:3rem;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:4.5rem;
                    max-width:800px;
                `}
            >
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Container>
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    text-align:center;
`
const Title = styled.h1`
    color: ${props => props.theme.color.blue.dark};
    font-size:1.5rem;
    @media (min-width:992px) {
        font-size:3rem;
        text-transform:uppercase;
    }
`
const Description = styled.p`
    font-size:1rem;
    padding:0.5rem 0 0 0;
    @media (min-width:992px) {
        padding:0.5rem 0 0;
        font-size:1.25rem;
    }
`