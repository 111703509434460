import React from 'react'
import styled from 'styled-components'

export default ({
    title,
    children
}) => {
    return (
        <Wrapper>
            <Title>{title}</Title>
            {children}
        </Wrapper>
    )
}

// Styled Components
const Wrapper = styled.div`
    border:0.25rem solid rgb(237, 237, 241);
    border-radius:0.25rem;
    padding: 0.75rem 0;
    background-color:rgba(255,255,255,0.75);
`
const Title = styled.h2`
    border-left:0.25rem solid ${props => props.theme.color.blue.primary};
    padding-left:0.75rem;
    margin-left:-0.25rem;
    margin-bottom:0.75rem;
    font-size:1.25rem;
    color:${props => props.theme.color.blue.dark};
    @media (min-width:992px) {
        font-size:2rem;
    }
`