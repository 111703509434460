import React from 'react'
import styled from 'styled-components'

// Assets
import BackgroundImage from '../../images/background/map.svg'

// Components
import Container from '../../components/container'
import Form from './form/index'
import Sidebar from './sidebar/index'
import Header from './header'

export default () => {
    return (
        <React.Fragment>
            <Header 
                title={`Contact Us`}
                description={`Get in touch with experts that can solve all your residential construction needs.`}
            />
            <Section>
                <Container
                    sm={`
                        padding-bottom:3rem;
                        display:grid;
                        grid-row-gap:1.5rem;
                    `}
                    lg={`
                        grid-template-columns: 8fr 4fr;
                        grid-column-gap:4.5rem;
                        padding-top:4.5rem;
                        padding-bottom:4.5rem;
                    `}
                >
                    <Form/>
                    <Sidebar/>
                </Container>
            </Section>
            <Background/>
        </React.Fragment>
    )
}


// Styled Components
const Section = styled.section`
`
const Background = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    bottom:0;
    background-image:url(${BackgroundImage}), linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
    text-align:center;
    z-index:-1;
    opacity:0.35;
    background-size: 300%;
    background-position-y:-40%;
    background-position-x:center;
    background-repeat:repeat;
    box-shadow: 
        inset 0px 2.5rem 1rem -1.5rem rgb(255,255,255),
        inset 0px -2.5rem 1rem -1.5rem rgb(245,245,250)
    ;
    @media (min-width:992px) {
        background-size: cover;
        background-position-y:bottom;
        background-position-x:center;
        background-repeat:no-repeat;
        box-shadow: 
            inset 0px 6rem 1rem -4.5rem rgb(255,255,255),
            inset 0px -6rem 1rem -4.5rem rgb(245,245,250)
        ;
    }
`